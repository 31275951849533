import { AbsoluteCenter, Spinner } from '@chakra-ui/react';

const AppLoader = ({ pastDelay = true }: { pastDelay?: boolean }): React.ReactNode => {
  if (pastDelay) {
    return (
      <AbsoluteCenter>
        <Spinner size="lg" />
      </AbsoluteCenter>
    );
  }
  return null;
};

export default AppLoader;
