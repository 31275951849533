import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Skeleton } from 'components/ui/skeleton';
import { Organization, useOrg } from 'hooks/useOrg';
import { PartnerOrganization } from 'types/organizations';

import { PartnerOrgTableRow } from './partner-table-row';

type PartnerOrgTableProps = {
  data: PartnerOrganization[];
  onOrgSwitch: (org: Organization) => void;
  isLoading: boolean;
  query?: string;
};

const TABLE_HEAD = ['Id', 'Name', 'Plan', 'Unapproved Registrations', 'Unapproved Filings', 'Pending Tasks', 'Type'];

export const PartnerOrgTable = ({ data, onOrgSwitch, isLoading, query }: PartnerOrgTableProps) => {
  const { orgId } = useOrg();
  const isDataEmpty = !isLoading && (!data || data.length === 0);

  if (isDataEmpty) {
    return <TableEmptyState tableName="Organizations" isSearchApplied={!!query} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  const reorderedData = [...data.filter(item => item.id === orgId), ...data.filter(item => item.id !== orgId)];

  return (
    <KDataTable headers={TABLE_HEAD} height="100%">
      {reorderedData.map(row => (
        <PartnerOrgTableRow key={row.id} {...row} onOrgSwitch={onOrgSwitch} />
      ))}
    </KDataTable>
  );
};
