import { Heading, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { getPartnerOrganizations } from 'apis/organizations-apis';
import AppHeader from 'app/app-header';
import { useAppLoaderWrapper } from 'app/app-loader-wrapper';
import { PartnerOrgToolbar } from 'app-header-toolbar/partner-org-toolbar';
import { PaginationButtons } from 'components/pagination/pagination';
import KSearchInput from 'components/SearchInput';
import { useHandleNotification } from 'hooks/useApiNotification';
import { Organization, setOrg, useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE } from 'hooks/useTableFilters';
import { clearOrgDataFromWindow } from 'hooks/useTrpcWithPropelAuth';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { markKintsugiAppAsInstalled } from 'utils/rippling-oauth';

import { PartnerOrgTable } from './components/table';

const PartnerOrg = () => {
  const { orgId } = useOrg();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { open, onOpen, onClose } = useDisclosure();
  const { showLoader, hideLoader } = useAppLoaderWrapper();

  const [{ page, size }, setPagination] = useState<{ page: number; size: number }>({
    page: 1,
    size: 25,
  });

  const [query, setQuery] = useState('');
  const { handleFailNotification } = useHandleNotification();

  useHotkeys('ctrl+p', () => onOpen(), [open]);

  const archiveOrgId = searchParams.get('archiveOrg');
  const serviceId = searchParams.get('service_id');
  const code = searchParams.get('code');
  const redirectUri = searchParams.get('redirect_uri');

  //for shopify
  const switchOrgForShopifyURL = searchParams.get('shop');
  const shopId = switchOrgForShopifyURL?.split('.')[0] || '';

  const { data, isPending } = useQuery({
    queryKey: ['orgs', page, size, query],
    queryFn: async () => {
      const { data } = await getPartnerOrganizations(orgId, page, size, query);
      return data;
    },
    select: data => {
      return {
        items: data.items.filter((org: any) => org.external_id !== archiveOrgId),
        pages: data.pages,
        total: data.total,
      };
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  const pages = data?.pages || 1;
  const orgData = data?.items || [];
  const isPaginationEnable = orgData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;

  const handleRedirection = async (orgId: string) => {
    try {
      if (serviceId === 'rippling' && code && redirectUri) {
        await handleHrisRedirection(orgId);
      } else if (switchOrgForShopifyURL && shopId) {
        navigateToShopify();
      } else {
        handleFallbackNavigation();
      }
    } catch (error) {
      console.error('Error during redirection:', error);
      handleFailNotification({
        message: `An error occurred during redirection.`,
      });
    }
  };

  const handleHrisRedirection = async (orgId: string) => {
    if (!code || !serviceId || !redirectUri) {
      return;
    }
    showLoader();
    const apideck = await createApiDeckConnection(orgId, serviceId, {});
    if (apideck.data) {
      await markKintsugiAppAsInstalled(code, orgId, apideck.data.connection_id);
      await updateApiDeckConnections(apideck.data.connection_id, orgId, 'ACTIVE');
      window.location.href = decodeURIComponent(redirectUri);
    } else {
      handleFailNotification({
        message: `Failed to connect to the ${serviceId} service.`,
      });
    }
    hideLoader();
  };

  const navigateToShopify = () => {
    const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
    navigate(path);
  };

  const handleFallbackNavigation = () => {
    navigate('/', { replace: true });
  };

  const handleOrgSwitch = async (selectedOrg: Organization) => {
    if (orgId === selectedOrg.orgId) {
      handleClose();
      await handleHrisRedirection(orgId);
      return;
    }

    // Clear token from window so react query does not make an
    // api request with previous org access token
    clearOrgDataFromWindow();
    handleClose();
    setOrg(selectedOrg);
    await handleRedirection(selectedOrg.orgId);
  };

  const handlePagination = async (page: number, size: number) => {
    setPagination({ page, size });
  };

  const handleClose = () => {
    setQuery('');
    setSearchParams({});
    setPagination({ page: 1, size: 25 });
    onClose();
    if (switchOrgForShopifyURL && shopId) {
      const path = `/connect/shopify?shopURL=${switchOrgForShopifyURL}`;
      navigate(path);
    }
  };

  return (
    <Stack justifyContent={'space-between'} height={'100%'}>
      <HStack justifyContent={'space-between'}>
        {orgId ? (
          <AppHeader />
        ) : (
          <Heading as={'h1'} fontSize={'20px'} fontWeight={500} color={'gray.900'} py={4}>
            Select Organization
          </Heading>
        )}
        <HStack gap={4} width={'380px'}>
          <KSearchInput query={query} setQuery={setQuery} />
          <PartnerOrgToolbar />
        </HStack>
      </HStack>
      <PartnerOrgTable data={data?.items || []} onOrgSwitch={handleOrgSwitch} isLoading={isPending} query={query} />
      {isPaginationEnable && (
        <PaginationButtons
          size={size}
          currentPage={page}
          totalPages={pages}
          onPageClick={handlePagination}
          onSizeChange={handlePagination}
          styles={{
            justifyContent: 'center',
          }}
        />
      )}
    </Stack>
  );
};

export default PartnerOrg;
