import { Badge, Button, ClipboardRoot, Flex, HStack, Table, Text } from '@chakra-ui/react';
import { ClipboardIconButton } from 'components/ui/clipboard';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import { PartnerOrganization } from 'types/organizations';

type PartnerOrgTableRowProps = PartnerOrganization;

export const PartnerOrgTableRow = ({
  id,
  name,
  billing_plan,
  pending_tasks,
  unpaid_filings,
  unregistered_registrations,
  onOrgSwitch,
  external_id,
  is_test,
}: PartnerOrgTableRowProps) => {
  const { orgId } = useOrg();

  const handleClick = () => {
    onOrgSwitch({
      orgId: id,
      name: name,
      externalId: external_id,
      isTest: is_test,
    });
  };

  return (
    <Table.Row _hover={{ bg: 'gray.50' }} className="group">
      <Table.Cell width={'100px'}>
        <Flex alignItems="center">
          <Tooltip content={id}>
            <Text lineClamp={1} maxWidth={'100px'}>
              {id}
            </Text>
          </Tooltip>
          <ClipboardRoot maxW="300px" value={id} opacity={0} _groupHover={{ opacity: 1 }}>
            <Tooltip content={'copy'}>
              <ClipboardIconButton variant="transparent-with-icon" />
            </Tooltip>
          </ClipboardRoot>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <HStack>
          <Tooltip content={name}>
            <Text lineClamp={1} maxWidth={'150px'}>
              {name}
            </Text>
          </Tooltip>
          {orgId === id && (
            <Badge width={'60px'} colorPalette={'blue'}>
              Current
            </Badge>
          )}
        </HStack>
      </Table.Cell>
      <Table.Cell>
        <Text>{billing_plan}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{unregistered_registrations}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{unpaid_filings}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{pending_tasks}</Text>
      </Table.Cell>
      <Table.Cell>
        <HStack width={'220px'} justifyContent={'space-between'}>
          <Text>{is_test ? 'Test' : 'Live'}</Text>
          <Button
            size="sm"
            variant={'outline'}
            colorPalette={'blue'}
            onClick={handleClick}
            opacity={0}
            _groupHover={{ opacity: 1 }}
          >
            View Organization
          </Button>
        </HStack>
      </Table.Cell>
    </Table.Row>
  );
};
